<template>
  <div style="padding-left: 100px; padding-right: 100px;">
    <navbar />
    <side-bar />
    <v-row>
      <v-col cols="12" sm="2">
        <v-select
          v-model="tag_list_selected"
          :items="typeTag"
          label="Choix du type de matériel"
          chips
          persistent-hint
          @change="selectList"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2">
        <v-select
          v-model="tag_selected"
          :items="listGenerated"
          label="Tags to view"
          chips
          persistent-hint
          @change="Validate"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="1"></v-col>
      <v-col cols="12" sm="3" v-if="tag_selected.length" style="margin-top: 25px;">
        Durée totale d'utilisation : {{ duree_totale }}
      </v-col>
      <v-col cols="12" sm="1"></v-col>
      <v-col cols="12" sm="2">
        <v-btn
          v-if="tag_selected.length"
          style="margin-top: 25px; background-color: var(--vertfonce); border-radius: 10px; font-size: 15px; color: white;"
          @click="showCustomExport = true"
        >Export CSV personnalisé</v-btn>
      </v-col>
    </v-row>

    <v-row v-if="sequences.length">
      <v-col cols="12">
        <v-data-table :headers="headers" :items="sequences" class="elevation-1">
          <template v-slot:item.start_date="{ item }">
            {{ formatDate(item.start_date) }}
          </template>
          <template v-slot:item.end_date="{ item }">
            {{ formatDate(item.end_date) }}
          </template>
          <template v-slot:item.duration="{ item }">
            {{ item.duration }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              style="background-color: var(--bleu); border-radius: 10px; font-size: 15px; color: white;"
              @click="exportData(item)"
            >Export CSV</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="showProgessBar" max-width="450" persistent style="background-color: rgba(0, 0, 0, 0.5);">
      <v-progress-linear :color="isError ? 'red' : 'green'" indeterminate style="height: 8px;"></v-progress-linear>
      <div :style="{ width: '100%', textAlign: 'center', marginTop: '15px', color: isError ? 'red' : 'white' }">
        <p>{{ message }}</p>
      </div>
    </v-dialog>

    <v-dialog v-model="showCustomExport" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Paramètres d'exportation personnalisée</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" style="margin-top: 20px;">
              <vue2-datepicker
                v-model="dateRange"
                range
                type="datetime"
                style="width: 100%; height: 50px;"
                format="YYYY-MM-DD HH:mm"
                :time-picker-options="{ start: '00:00', step: '00:05', end: '23:55' }"
              ></vue2-datepicker>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4">
              <v-select v-model="selectedUnit" :items="units" label="Unité" chips persistent-hint item-text="value" item-value="units"></v-select>
            </v-col>
            <v-col cols="12" sm="8">
              <v-slider v-model="period" :max="maxPeriod" :min="5" :step="5" thumb-label="always" style="margin-top: 40px;"></v-slider>
            </v-col>
          </v-row>
          <span>Periode: {{ period }} {{ selectedUnit }}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showCustomExport = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="customExportData">Export</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SideBar from '../components/SideBar'
import navbar from '@/components/NavBarCloud.vue';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { groupDataByTimeInterval, calculateSummaries, exportDataCSV } from '../functions/traitement.js';
import { formatDuration } from '../functions/format.js'
import { getNamesAndIds, getDataForExport } from '../functions/get-data.js';

moment.locale('fr');

export default {
    name: "json",
    components: {
        SideBar,
        navbar,
    },
    data() {
        return {
        //***************** Data formulaire *********************//
        value: 30,
        tag_to_select: [],
        tag_selected: [],
        showProgessBar: false,
        isError: false,
        message: '',
        showCustomExport: false,
        tag_and_mat_selected: [],
        sequences: [],
        intervalles: ['10 second', '30 second', '1 minute', '5 minute', '15 minute', '30 minute', '1 hour'],
        intervalleChosen: [],
        duree_totale: '',
        menu: false,
        menu2: false,
        startMenu: false,
        endMenu: false,
        tag_id: [],
        showStartTimePicker: false,
        showEndTimePicker: false,
        dateRange: {
            start: new Date().toISOString().substr(0, 10),
            end: new Date().toISOString().substr(0, 10),
        },
        timeRange: {
            start: '12:00',
            end: '12:00',
        },
        units: [
            { name: 'Seconds', value: 'Secondes' },
            { name: 'Minutes', value: 'Minutes' },
            { name: 'heures', value: 'Heures' },
        ],
        selectedUnit: 'seconds',
        period: 1,
        maxPeriod: 60, // Ajustez cela selon vos besoins
        dialog: false,
        data_loaded: [],
        headers: [
            { text: 'Date de début', value: 'start_date' },
            { text: 'Date de fin', value: 'end_date' },
            { text: 'Durée en secondes', value: 'formatted_duration' },
            { text: 'Actions', value: 'action', sortable: false }
        ],
        typeTag: ['implant', 'capteur ammoniaque', 'capteur environnement', 'sphère de jeu', 'vibration'],
        tag_list_selected: '',
        listGenerated: [],
        }
    },

    computed: {
        dateRangeText() {
        return this.date + ' à ' + this.time1 + ' ~ ' + this.date1 + ' à ' + this.time2;
        },
    },

    methods: {
    async selectList() {
        this.listGenerated = [];
        const mapping = {
            'implant': { attributed: 'tag_attributed', renamed: 'tag_renamed' },
            'matrice': { attributed: 'matrice_attributed', renamed: 'matrice_renamed' },
            'capteur ammoniaque': { attributed: 'capteur_amonniaque', renamed: 'amonniaque_renamed' },
            'capteur environnement': { attributed: 'capteur_environnement', renamed: 'environnement_renamed' },
            'sphère de jeu': { attributed: 'capteur_sphere', renamed: 'sphere_renamed' },
            'vibration': { attributed: 'capteur_vibration', renamed: 'vibration_renamed' }
        };
        const selectedKey = mapping[this.tag_list_selected];
        const attributedKey = selectedKey.attributed;
        const renamedKey = selectedKey.renamed;

        if (selectedKey) {
            const data = await getNamesAndIds(attributedKey, renamedKey);
            console.log(data)
            let ids = data[attributedKey];
            let names = data[renamedKey];
            this.tag_id = ids;
            if (ids && ids.length > 0) {
            for (let i = 0; i < ids.length; i++) {
                if (names[i] === "noname") {
                this.listGenerated.push(ids[i]);
                } else {
                this.listGenerated.push(names[i]);
                }
            }
            }
        }
    },

    async Validate() {
        this.tag_and_mat_selected = [];
        this.tag_and_mat_selected.push(this.tag_id[this.listGenerated.indexOf(this.tag_selected)])
        this.show = 1;
        await this.fetchSequences();
    },

    async fetchSequences() {
        const tag_id = encodeURIComponent(this.tag_and_mat_selected);
        console.log(tag_id)
        const url = this.$api.getRESTApiUri() + `/data-transmission-duration/${tag_id}`;
        try {
            const response = await fetch(url);
            const data = await response.json();

            let total_duration = 0;
            data.forEach(async item => {
            total_duration += item.duration;
            item.formatted_duration = await formatDuration(item.duration);
            });
            this.duree_totale = await formatDuration(total_duration)
            data.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
            this.sequences = data;
        } catch (error) {
            console.error("Error fetching sequences:", error);
        }
    },

    async exportData(item) {
        this.showProgessBar = true;
        this.isError = false;
        this.message = "Exporting data...";
        const suffixe = ['_data_vibration', '_data_ppg']
        try {
            const tags_to_get_data = [];
            const tag_selected = this.tag_id[this.listGenerated.indexOf(this.tag_selected)];
            tags_to_get_data.push(tag_selected);
            const date_debut = item.start_date;
            const date_fin = item.end_date;

            const data = await getDataForExport(tags_to_get_data, date_debut, date_fin, false);
            const processed_data = data.processed_data
            // console.log(processed_data.length)
            for (let i = 0; i < processed_data.length; i++) {
              for (let j = 1; j < processed_data[i].length; j++) {
                  let row = processed_data[i][j];
                  if (row[0]) {
                    row[0] = this.formatDateToLocalISO(new Date(row[0]));
                  }
              }
              const csvData = Papa.unparse(processed_data[i]);
              const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

              if(tag_selected.startsWith('ppg1_')){
                saveAs(blob, tags_to_get_data[0] + suffixe[i] + '.csv');
              }else{
                saveAs(blob, tags_to_get_data[i] + '.csv');
              }
            }
            this.message = "Data exported successfully!";
        } catch (error) {
            this.isError = true;
            this.message = "Error exporting data. Please try again.";
            console.error("Error exporting data:", error);
        } finally {
            this.showProgessBar = false;
        }
    },

    async customExportData() {
        this.showProgessBar = true;
        this.isError = false;
        this.message = "Exporting custom data...";
        try {
            let multiplicateurPeriode = 1;
            switch (this.selectedUnit) {
            case 'Secondes':
                multiplicateurPeriode = 1;
                break;
            case 'Minutes':
                multiplicateurPeriode = 60;
                break;
            case 'Heures':
                multiplicateurPeriode = 1;
                break;
            }
            const periode = this.period * multiplicateurPeriode;
            const tags_to_get_data = [];
            const tag_selected = this.tag_id[this.listGenerated.indexOf(this.tag_selected)];
            tags_to_get_data.push(tag_selected);
            const date_debut = moment(this.dateRange[0]).format();
            const date_fin = moment(this.dateRange[1]).format();
            const data = await getDataForExport(tags_to_get_data, date_debut, date_fin);
            const processed_data = JSON.parse(data.processed_data);
            const groupedData = await groupDataByTimeInterval(processed_data[0], periode);
            const summariesData = await calculateSummaries(groupedData);
            await exportDataCSV(summariesData, this.tag_selected);
            this.message = "Custom data exported successfully!";
        } catch (error) {
            this.isError = true;
            this.message = "Error exporting custom data. Please try again.";
            console.error("Error exporting custom data:", error);
        } finally {
            this.showProgessBar = false;
        }
    },

    formatDate(date) {
      return new Date(date).toLocaleString('fr-FR', { timeZone: 'UTC' });
    },

    formatDateToLocalISO(date) {
      const tzOffset = date.getTimezoneOffset() * 60000;
      const localISOTime = new Date(date - tzOffset).toISOString().slice(0, -1);
      return localISOTime.replace('T', ' ');
    },
  },
}
</script>

<style>
.align-center {
  margin: auto;
}
</style>